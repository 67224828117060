<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card class="overflow-hidden" :disabled="disableWhileLoading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ "Customers" }}</div>
        <v-spacer></v-spacer>

        <v-btn :loading="loading" text @click="getCustomers">
          <v-icon>mdi-autorenew</v-icon>
          {{ $t("labelUpdate") }}
        </v-btn>
      </v-toolbar>
      <div class="d-flex align-center">
        {{/* Filter */ }}
        <div class="col-sm-4">
          <v-text-field
            v-model.trim="query"
            :label="'Search'"
            hide-details
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            dense
          />
        </div>

        <!-- <div class="col-sm-6 d-flex">
          <template v-if="filterDate == 'custom'">
            <ma-date-picker class="pt-3 mt-3" v-model="dateFrom" label="From" past />
            <ma-date-picker class="pt-3 mt-3 mx-1" v-model="dateTo" label="To" past />
          </template>
          <template v-if="dateFrom || dateTo">
            <div class="mt-3 pt-3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    small
                    dark
                    x-small
                    v-on="on"
                    class="mr-1"
                    color="error"
                    @click="clearDates()"
                  >
                    <v-icon dark> mdi-refresh </v-icon>
                  </v-btn>
                </template>
                <span>Clear Dates</span>
              </v-tooltip>
            </div>
          </template>
        </div> -->
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="openFilters"
          ><v-icon>mdi-filter-variant</v-icon> Filters</v-btn
        >
      </div>

      <v-divider></v-divider>
      <template v-if="!noDatesFT">
        <v-layout class="pa-4" column>
          <div class="mr-2 text-capitalize">
            {{ filterDate.replace("-", " ") }}
          </div>
          <v-layout column class="text-body-1 text--secondary">
            <div>
              From
              <span class="text-caption">{{ prettyDate(query1.date1) }}</span>
            </div>
            <div>
              To
              <span class="text-caption">{{ prettyDate(query1.date2) }}</span>
            </div>
          </v-layout>
        </v-layout>
        <v-divider></v-divider>
      </template>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalcustomers"
        :items="customers"
        :show-select="isAdmins"
        :loading="disableWhileLoading"
        :footer-props="footerProps"
        item-key="uuid"
        @click:row="showDetails"
      >
      <template v-slot:[`header.active`]="{ header }">
          {{ `${header.text}(${summary.totalactives})` }}
        </template>
        <template v-slot:[`header.healths`]="{ header }">
          {{ `${header.text}(${summary.healths})` }}
        </template>
        <template v-slot:[`header.zipCode`]="{ header }">
          {{ `${header.text}` }}
          <template v-if="summary.dataZips.length!=0">
          <v-icon color="success" @click="summary.dialog=true;">mdi-eye</v-icon>
          </template>
        </template>
        <template v-slot:[`item.fullname`]="{ item }">
          <div>
            {{ item.name ? item.name : "" }}
            {{ item.lastName ? item.lastName : "" }}
          </div>
        </template>
        <template v-slot:[`item.zipCode`]="{ item }">
          <div>
            {{ getzipCode(item) }}
          </div>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <template v-if="item.patients && item.patients.length != 0"
            ><v-icon color="success" small>mdi-check</v-icon></template
          >
          <template v-else>
            <v-icon color="error" small>mdi-close</v-icon></template
          >
        </template>
        <template v-slot:[`item.healths`]="{ item }">
          <template v-if="item.patients && item.patients.length != 0">{{
            item.patients.length
          }}</template>
          <template v-else>0</template>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <div class="cursor-pointer">
            {{
              item.phone
                ? "..... " + item.phone.slice(item.phone.length - 4)
                : "-"
            }}
          </div>
        </template>
      </v-data-table>
      <v-dialog
        v-model="summary.dialog"
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card class="py-4 px-2 ">
          <template>
            <div class="d-flex">
              <div class="col-sm-8">
          <v-text-field
            v-model.trim="summary.search"
            :label="'Search Zip Code'"
            hide-details
            prepend-inner-icon="mdi-magnify"
            clearable
            
            dense
          />
        </div>

            </div>
            <v-data-table
              :headers="summary.headers"
              :search="summary.search"
              :items="summary.dataZips"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`header.active_policies`]="{ header }">
                {{ `${header.text} (${summary.totalZips}) ` }}</template
              >
              <template v-slot:[`item.zip_code`]="{ item }">
          <div>
            {{ item.zip_code?item.zip_code:'No Zip'}}
          </div>
        </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
    </v-card>

    <template v-if="filterExpand">
      <v-navigation-drawer v-model="filterExpand" app v-cloak>
        <div class="d-flex pa-2">
          <span class="py-1">Filters</span><v-spacer></v-spacer
          ><v-btn text @click="closeFilters"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="12" class="px-1 mt-1" sm="12">
            <v-select
              v-model="stateSelect"
              label="by State"
              :items="states"
              prepend-inner-icon="mdi-magnify"
              item-text="name"
              multiple
              :loading="loadingStates"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              @change="getCustomers"
            />
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="mt-3 px-1 mb-0" sm="12">
            <v-autocomplete
              :disabled="stateCities.length == 0"
              label="by City"
              v-model="citySelect"
              :items="stateCities"
              item-text="name"
              item-value="id"
              multiple
              :loading="loadingCities"
              autocomplete="off"
              @change="getCustomers"
              outlined
              clearable
              dense
            />
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="px-1" sm="12">
            <v-text-field
              label="by Zip Code"
              v-model="zipCodeSelect"
              autocomplete="postal-code"
              outlined
              clearable
              dense
            />
          </v-col>
          <v-col cols="12" class="px-1" sm="12">
            <v-text-field
              label="Zip Codes"
              v-model="zipCode"
              autocomplete="postal-code"
              outlined
              readonly
              dense
            />
            <template v-if="zipCode.length!=0">
              <div class="d-flex justify-end">
                <v-btn color="error" small @click="clearZips">Clear zips</v-btn>
              </div>
             
            </template>
          </v-col>
        </v-row> </v-navigation-drawer
    ></template>
  </div>
</template>

<script>
import { getAPI } from "@/api/axios-base";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { notifyError } from "@/components/Notification";
import { cleanPhoneNumber, isPhoneNumber } from "@/utils/formatPhone";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";

export default {
  components: {
    MaDatePicker,
  },
  data() {
    return {
      filterExpand: false,
      filterDate: "custom",
      dateTo: null,
      dateFrom: null,
      loading: false,
      loadingSummary: false,
     
      summary: {
        dataZips: [],
        totalZips:0,
        healths:0,
        totalactives:0,
        search:null,
        dialog: false,
        headers:[
          {
          text: "Zip Code",
          show: true,         
          value: "zip_code",
          sortable: true,
        },
          {
          text: "Policies",
          show: true, 
          align:'center',        
          value: "active_policies",
          sortable: true,
        },
        ]
      },
      query1: {
        date1: "",
        date2: "",
      },
      selected: [],
      customers: [],
      query: null,
      zipCodeSelect:null,
      stateSelect: [],
      citySelect: [],
      zipCode: [],
      stateCities: [],
      loadingCities: false,
      totalcustomers: 0,
      isAdmins: false,
      options: {},
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
      headers: [
        {
          text: "Fullname",
          show: true,
          width: 200,
          value: "fullname",
          sortable: false,
        },
        {
          text: "Phone",
          show: true,
          width: 200,
          value: "phone",
          sortable: false,
        },
        {
          text: "Zip Code",
          show: true,
          width: 200,
          value: "zipCode",
          sortable: false,
        },
        {
          text: "Active",
          show: true,
          width: 200,
          value: "active",
          sortable: false,
        },
        {
          text: "Healths",
          show: true,
          width: 200,
          value: "healths",
          sortable: false,
        },
        {
          text: "Autos",
          show: true,
          width: 200,
          value: "autos",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["agents", "states", "loadingStates"]),
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor"]),
    disableWhileLoading() {
      return this.loading;
    },

    isZipCode() {
      if (this.zipCodeSelect == null || this.zipCodeSelect == "") return true;
      return /^[0-9]{5}$/.test(this.zipCodeSelect);
    },

    noDatesFT() {
      if (
        this.dateFrom == null ||
        this.dateFrom == "" ||
        this.dateFrom == undefined ||
        this.dateTo == null ||
        this.dateTo == undefined ||
        this.dateTo == ""
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getCustomers();
      }
    },
    query(val) {
      if (val == null) {
        this.getCustomers();
      } else if (val.length >= 3 || val.length == 0) {
        if (isPhoneNumber(val)) {
          this.query = cleanPhoneNumber(val);
          if (this.query[0] == "1") {
            this.query = this.query.slice(1);
          }
        }
        this.getCustomers();
      }
    },
    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.getCustomers();
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.getCustomers();
      }
    },

    stateSelect: function (val) {
      if (val.length==0 ) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(val[0]).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },
    zipCode:{
      handler(){
        this.zipCodeSelect=''
        this.getCustomers();
      }
    },
    zipCodeSelect: {
      handler(val) {     
          if (this.isZipCode && val!=null&&val!='') {
            this.zipCode.push(val)           
          }       
      },
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    ...mapMutations(["mutFilterDrawerExpand", "mutDrawerExpand"]),
    clearDates() {
      this.dateFrom = "";
      this.dateTo = "";
      this.getCustomers();
    },
    clearZips(){
      this.zipCode=[]
    },

    getCustomers() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      let body = {
        offset: (page - 1) * itmper,
        limit: itmper,
        query: this.query,
        dateInterval: {
          date1: moment(this.dateFrom).startOf("day").toISOString(),
          date2: moment(this.dateTo).endOf("day").toISOString(),
        },
      };

      this.query1 = body.dateInterval;

      this.loading = true;
      if (body.query == null) {
        delete body.query;
      }
      if (this.noDatesFT) {
        delete body.dateInterval;
      }
      if (this.stateSelect.length!=0) {
        body = {...body,states:this.stateSelect.map(ss=>{
          return ss.toString()
        })}
      }
      if (this.citySelect.length!=0) {
        body = {...body,cities:this.citySelect.map(ss=>{
          return ss.toString()
        })}
      }
      if (this.zipCode.length!=0) {
        body = {...body,zipCodes:this.zipCode.map(ss=>{
          return ss.toString()
        })}
      }
      getAPI
        .post("/customer/filterList", body)
        .then((res) => {
          this.customers = res.data.result;
          this.totalcustomers = res.data.count;
          this.loading = false;
          this.getSummary();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getSummary() {
      this.loadingSummary=true;
      let body ={};
      if (body.query == null) {
        delete body.query;
      }
      if (this.stateSelect.length!=0) {
        body = {...body,states:this.stateSelect.map(ss=>{
          return ss.toString()
        })}
      }
      if (this.citySelect.length!=0) {
        body = {...body,cities:this.citySelect.map(ss=>{
          return ss.toString()
        })}
      }
      if (this.zipCode.length!=0) {
        body = {...body,zipCodes:this.zipCode.map(ss=>{
          return ss.toString()
        })}
      }
      getAPI
        .post("/customer/summaryFilterList", body)
        .then((res) => {
        this.summary.dataZips=res.data.filter(rd=>rd.zip_code!='TOTAL'&&rd.zip_code!='UNKNOWN');
        this.summary.totalactives=res.data.find(rd=>rd.policy_type=='TOTAL').active_policies;
        this.summary.totalZips=res.data.find(rd=>rd.zip_code=='TOTAL').active_policies;
        this.summary.healths=res.data.find(rd=>rd.policy_type=='HEALTH').active_policies;
          this.loadingSummary = false;
          
        })
        .catch((error) => {
          this.loadingSummary = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    showDetails(customer) {
      this.$router.push(`/customers/details/${customer.uuid}`);
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    openFilters() {
      this.filterExpand = !this.filterExpand;
      this.mutFilterDrawerExpand(this.filterExpand);
      this.mutDrawerExpand(this.filterExpand);
    },
    closeFilters() {
      this.filterExpand = false;
      this.mutFilterDrawerExpand(this.filterExpand);
      this.mutDrawerExpand(this.filterExpand);
    },

    getzipCode(item) {
      if (item.address) {
        return item.address.zipCode;
      }
      return "-";
    },
  },
  async mounted() {
    if (this.isAdmin && this.isSuper) {
      this.isAdmins = true;
    }
    await this.actListStates();
  },
  destroyed() {
    this.closeFilters();
  },
};
</script>
<style lang=""></style>
